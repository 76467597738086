import React from 'react';

import styles from './thread-list-item.module.css';

const ThreadListItem = ({ thread }) => {
	return (
		<li className={styles.thread__list__item}>
			<div className={styles.thread__item__text}>{thread.title}</div>
		</li>
	);
};

export default ThreadListItem;